<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <b-row>
        <b-col cols="12">
          <b-card class="my-card m-0">
            <b-table
              striped
              small
              id="myTabless"
              bordered
              selectable
              selected-variant="primary"
              select-mode="single"
              responsive="sm"
              :items="documentType"
              :fields="field"
              @row-clicked="clicked"
              @row-contextmenu="handleContextMenuEvent"
              head-row-variant="secondary"
            >
              <template #cell(checkbox)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                </b-form-checkbox>
              </template>
            </b-table>
          </b-card>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                v-b-modal="'ModalTransactionDocumentTypeEdit'"
              >
                <feather-icon icon="Edit2Icon" size="16" />
                <span class="ml-75">Изменить</span>
              </b-link>
            </li>
          </vue-context>
        </b-col>
      </b-row>
    </div>
    <modal-transaction-document-type @refresh="refresh" />
    <modal-transaction-document-type-edit
      :id="id"
      :editDocument="editDocument"
      @refresh="refresh"
    />
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
  </div>
</template>

<script>
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalTransactionDocumentType from "@/views/component/Modal/ModalTransactionDocumentType/ModalTransactionDocumentType.vue";
import ModalTransactionDocumentTypeEdit from "@/views/component/Modal/ModalTransactionDocumentType/ModalTransactionDocumentTypeEdit.vue";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";

export default {
  components: {
    VueContext,
    ToastificationContent,
    ModalTransactionDocumentType,
    ModalTransactionDocumentTypeEdit,
  },
  data() {
    return {
      documentType: [],
      showPreloader: false,
      field: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "ID", sortable: true, thStyle: { width: "50px" } },
        {
          key: "code_type_doc",
          label: "Код",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "name",
          label: "Названия",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "name_for_performer",
          label: "Название для водителя",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "name_for_client",
          label: "Название для клиента",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "is_active",
          label: "Активность",
          sortable: true,
          thStyle: { width: "130px" },
        },
      ],
      fields: [
        { key: "id", label: "ID" },
        { key: "code_type_doc", label: "Код" },
        { key: "name", label: "Названия" },
        { key: "name_for_perfomer", label: "Название для водителя" },
        { key: "name_for_client", label: "Название для клиента" },
      ],
      id: null,
      editDocument: {},
    };
  },
  methods: {
    clicked(item, index, event) {
      this.id = item.id;
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.field.length) {
        return;
      }

      const clickedField = this.field[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;

      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get(`type-transaction`).then((res) => {
        this.documentType = res.data;
        this.$store.commit("pageData/setdataCount", this.documentType.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    sendToParent(tableData) {
      this.documentType = tableData;
      this.$store.commit("pageData/setdataCount", this.documentType.length);
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`type-transaction`, { params: param }).then((res) => {
          this.documentType = res.data;
          this.$store.commit("pageData/setdataCount", this.documentType.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    editDicumentType() {
      this.$http
        .get(`type-transaction/${this.id}/edit`)
        .then((res) => {
          this.editDocument = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item.id;
      this.editDicumentType();
    },
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    resizeable();
    this.refreshTimer = setInterval(this.resizeable, 300);
    this.openFilter();
  },
  destroy() {
    clearInterval(this.refreshTimer);
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.$http.get(`type-transaction`, { params: param }).then((res) => {
          this.documentType = res.data;
          this.$store.commit("pageData/setdataCount", this.documentType.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
