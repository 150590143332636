<template>
  <div>
    <b-modal
      :id="'ModalTransactionDocumentTypeEdit'"
      title="Изменить тип документа"
      size="sm"
      ok-only
      ok-title="Добавить"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
    >
      <b-row>
        <b-col cols="12">
          <b-row class="mb-1">
            <b-col cols="12">
              <label class="mb-0">Код</label>
              <b-input v-model="editDocument.code_type_doc" placeholder="Код" />
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="12">
              <label class="mb-0">Названия</label>
              <b-input v-model="editDocument.name" placeholder="Название" />
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="12">
              <label class="mb-0">Название для водителя</label>
              <b-input
                v-model="editDocument.name_for_performer"
                placeholder="Название для водителя"
              />
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="12">
              <label class="mb-0">Название для клиента</label>
              <b-input
                v-model="editDocument.name_for_client"
                placeholder="Название для клиента"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12"><hr /></b-col>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button
            @click="editDocumentType"
            :disabled="submitButtonDisabled"
            class="col-md-5"
            size="sm"
            variant="primary"
          >
            Изменить
          </b-button>
          <b-button
            class="col-md-5"
            size="sm"
            variant="secondary"
            @click="$bvModal.hide('ModalTransactionDocumentTypeEdit')"
          >
            Отмена
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { BRow, BCol } from "bootstrap-vue";
export default {
  props: ["id", "editDocument"],
  components: {
    ToastificationContent,
    vSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      documentType: {
        code_type_doc: null,
        name: null,
        name_for_performer: null,
        name_for_client: null,
      },
      submitButtonDisabled: false,
    };
  },
  methods: {
    editDocumentType() {
      const data = JSON.parse(JSON.stringify(this.editDocument));
      this.$http
        .patch(`type-transaction/${data.id}`, data)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
      this.$bvModal.hide("ModalTransactionDocumentTypeEdit");
    },
  },
};
</script>
